import React from "react";
import { toastConfig } from "react-simple-toasts";
import "react-simple-toasts/dist/theme/dark-edge.css";
import "swiper/css";

toastConfig({
  theme: "dark-edge",
});

const GTAG = `G-Z1EPTHS2W4`;

export const wrapPageElement = ({ element }) => {
  return (
    <>
      <div
        dangerouslySetInnerHTML={{
          __html: `
            <!-- Google tag (gtag.js) -->
            <script async src="https://www.googletagmanager.com/gtag/js?id=G-Z1EPTHS2W4"></script>
            <script>
              window.dataLayer = window.dataLayer || [];
              function gtag(){dataLayer.push(arguments);}
              gtag('js', new Date());

              gtag('config', '${GTAG}');
            </script>
          `,
        }}
      />

      {element}
    </>
  );
};
